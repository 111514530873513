import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { getMeasurements } from '../services/api';
import '../styles.css';

const MeasurementList = () => {
    const [measurements, setMeasurements] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getMeasurements();
            setMeasurements(data);
        };
        fetchData();
    }, []);

    return (
        <List>
            {measurements.map((measurement) => (
                <ListItem
                    button
                    key={measurement._id}
                    component={Link}
                    to={`/measurements/${measurement._id}`}
                    style={{ marginBottom: '10px', borderRadius: '8px', border: '1px solid #ddd' }}
                >
                    <ListItemText
                        primary={<span className="red-text">ID: {measurement._id}</span>}
                        secondary={`Measurement: ${measurement.user_measurement.value} ${measurement.user_measurement.unit} - ${new Date(measurement.timestamp).toLocaleString()}`}
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default MeasurementList;