import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getMeasurementById } from '../services/api';
import '../styles.css';

const MeasurementDetail = () => {
    const { id } = useParams();
    const [measurement, setMeasurement] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getMeasurementById(id);
                setMeasurement(data);
            } catch (err) {
                setError('Failed to load measurement data.');
                console.error('Error loading measurement data:', err);
            } finally {
                setLoading(false);
            }
        };
        if (id) {
            fetchData();
        }
    }, [id]);

    // Using useCallback to create a stable function reference
    const handleClickOutside = useCallback((event) => {
        if (selectedImage && !event.target.closest('.full-size-image-container')) {
            setSelectedImage(null);
        }
    }, [selectedImage]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]); // Dependency array now includes the stable callback

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (!measurement) {
        return <Typography>No measurement selected</Typography>;
    }

    const renderLength = (length) => {
        if (!length) return <Typography>No length data available</Typography>;
        return (
            <div>
                <strong>Value:</strong> {length.value} {length.unit} <br />
                <strong>Value in cm:</strong> {length.value_in_cm} cm <br />
                <strong>Value in inches:</strong> {length.value_in_inches} inches
            </div>
        );
    };

    const renderSubtaskResults = (results) => {
        if (!results) return <Typography>No results available</Typography>;
        return (
            <ul>
                {Object.entries(results).map(([key, value]) => (
                    <li key={key}>
                        <strong className={key === 'id' ? 'red-text' : ''}>{key}:</strong> {renderValue(value)}
                    </li>
                ))}
            </ul>
        );
    };

    const renderValue = (value) => {
        if (value === null) return "N/A";
        if (typeof value === 'object' && value !== null) {
            if (value.hasOwnProperty('value') && value.hasOwnProperty('unit')) {
                return renderLength(value);
            } else {
                return <pre>{JSON.stringify(value, null, 2)}</pre>;
            }
        }
        return value;
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Measurement Details
            </Typography>
            <Typography variant="body1"><strong>User Measurement:</strong></Typography>
            {renderLength(measurement.user_measurement)}

            <Typography variant="body1">
                <strong>Timestamp:</strong> {new Date(measurement.timestamp).toLocaleString()}
            </Typography>
            <Box mt={4}>
                <Typography variant="h5">Images</Typography>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box mt={2}>
                        <Typography variant="h6">Original Picture:</Typography>
                        <img
                            src={measurement.original_pic}
                            alt="Original"
                            style={{ maxWidth: '100%' }}
                            onClick={() => handleImageClick(measurement.original_pic)}
                        />
                    </Box>
                    {measurement.transparent_url && (
                        <Box mt={2}>
                            <Typography variant="h6">Transparent Image:</Typography>
                            <img
                                src={measurement.transparent_url}
                                alt="Transparent"
                                style={{ maxWidth: '100%' }}
                                onClick={() => handleImageClick(measurement.transparent_url)}
                            />
                        </Box>
                    )}
                    {measurement.bump_annotated_img_path && (
                        <Box mt={2}>
                            <Typography variant="h6">Bump Annotated Image:</Typography>
                            <img
                                src={measurement.bump_annotated_img_path}
                                alt="Bump Annotated"
                                style={{ maxWidth: '100%' }}
                                onClick={() => handleImageClick(measurement.bump_annotated_img_path)}
                            />
                        </Box>
                    )}
                    {measurement.ar_annotated_img_path && (
                        <Box mt={2}>
                            <Typography variant="h6">AR Annotated Image:</Typography>
                            <img
                                src={measurement.ar_annotated_img_path}
                                alt="AR Annotated"
                                style={{ maxWidth: '100%' }}
                                onClick={() => handleImageClick(measurement.ar_annotated_img_path)}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
            <Box mt={4}>
                <Typography variant="h5">AR Results</Typography>
                {renderSubtaskResults(measurement.ar_results)}
            </Box>
            <Box mt={4}>
                <Typography variant="h5">Bumpboard Results</Typography>
                {renderSubtaskResults(measurement.bumpboard_results)}
            </Box>
            {selectedImage && (
                <div className="full-size-image-container" onClick={() => setSelectedImage(null)}>
                    <img src={selectedImage} alt="Full Size" className="full-size-image" />
                </div>
            )}
        </Box>
    );
};

export default MeasurementDetail;
