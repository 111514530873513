// src/services/api.js
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const getMeasurements = async () => {
    const response = await axios.get(`${API_BASE_URL}/debug/measurements`);
    return response.data.measurements;
};

export const getMeasurementById = async (id) => {
    const response = await axios.get(`${API_BASE_URL}/debug/measurements/${id}`);
    return response.data;
};

