import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';

const TopLevelPanel = ({ selectedTab, setSelectedTab }) => {
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="top level tabs">
                <Tab label="Measurement Visualizations" />
                <Tab label="Other Functionality" />
                {/* Add more tabs as needed */}
            </Tabs>
        </Box>
    );
};

export default TopLevelPanel;