import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, Box, Container, Grid, Paper, Typography } from '@mui/material';
import MeasurementList from './components/MeasurementList';
import MeasurementDetail from './components/MeasurementDetail';
import TopLevelPanel from './components/TopLevelPanel';

function App() {
    const [selectedTab, setSelectedTab] = useState(0);

    console.log('Selected tab:', selectedTab);

    return (
        <Router>
            <CssBaseline />
            <Container maxWidth="lg">
                <Box mt={4}>
                    <TopLevelPanel selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                    {selectedTab === 0 ? (
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Paper elevation={3} style={{ height: '80vh', overflowY: 'auto' }}>
                                    <MeasurementList />
                                </Paper>
                            </Grid>
                            <Grid item xs={8}>
                                <Paper elevation={3} style={{ padding: '20px', height: '80vh', overflowY: 'auto' }}>
                                    <Routes>
                                        <Route
                                            path="/measurements/:id"
                                            element={<MeasurementDetail />}
                                        />
                                        <Route
                                            path="/"
                                            element={<Typography>Select a measurement to view details.</Typography>}
                                        />
                                    </Routes>
                                </Paper>
                            </Grid>
                        </Grid>
                    ) : (
                        <Box mt={2}>
                            <Paper elevation={3} style={{ height: '80vh' }}>
                                <Typography>Other Functionality</Typography>
                            </Paper>
                        </Box>
                    )}
                </Box>
            </Container>
        </Router>
    );
}

export default App;